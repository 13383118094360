@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

@font-face {
    font-family: 'Poppins-Regular';
    src: url('./assets/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.roboto-mono-100 {
    font-family: "Roboto Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }

/* Base styles for larger screens */
@media screen and (min-width: 0px) {
    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 100vw;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 100vw;
        background: rgba(0, 0, 0, 0.366);
    }

    ::-webkit-scrollbar-thumb:hover {
        background: hsl(0, 0%, 65%);
    }

    .stupid {
        color: green !important;
    }

    .expanded-project {
        z-index: 5;
        position: fixed;
        width: 90vw;
        height: 90vh;
        /* height: 90dvh; */
        backdrop-filter: blur(5px);
        background: rgba(191, 191, 191, 0.6);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5em;
        padding: 2em 1em 1em 1em;
        border: 1px solid rgb(255, 255, 255);
        cursor: auto;
        border-radius: 20px;
        transition: 0.3s;
        animation-name: slidein;
        animation-duration: 0.2s;
        animation-fill-mode: backwards;
        transform-origin: 50vw 50vh;

        font-size:16px
    }

    /* .expanded-project::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
    } */

    @keyframes slidein {
        0% {
            scale: 0.01;
        }
        100% {
            scale: 1;
        }
    }

    .hidden-project {
        display: none;
    }

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 2em;
        padding-bottom: 15px;
        position: relative;
    }

    .back_button, .close_button {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 1.5em;
        height: 100%;
    }

    .back_button img, .close_button img {
        height: 1.8em;
        width: auto;
    }

    .tags {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        flex-grow: 1;
        margin: 0 1.5em;
    }

    .tag {
        background-color: transparent;
        border: 1px solid #ffffff;
        border-radius: 100px;
        padding: 5px 10px;
        margin: 5px;
        font-family: 'GilroyLight', sans-serif;
        color: white;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2em;
        line-height: 1.5;
    }

    .firstContainer {
        position: relative;
        background-color: #ffffff;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* justify-content: flex-end;  */
        /* Aligns other content to the bottom */
        /* align-items: flex-end;    */
        padding: 2em;
    }
    
    .topLine {
        display: flex;
        /* align-items: right; */
        gap: 10px;
    }
    
    .title {
        font-size: 7em;
        font-family: 'Roboto Mono';
        position: relative;             
    }
    
    .title:hover, .title.buzz {
        animation: buzz 0.2s infinite;
    }
    
    @keyframes buzz {
        0% { transform: translate(0px, 0px); }
        25% { transform: translate(-1px, 1px); }
        50% { transform: translate(1px, -1px); }
        75% { transform: translate(-1px, -1px); }
        100% { transform: translate(0px, 0px); }
    }


    .subHeading {
        font-size: 2em;
        font-family: 'Poppins-Regular';
        text-align: center;
        max-width: 50%;
        /* text-align: end;    */
               /* Align text to the right */
        margin-top: 10px;         /* Adds space between title and subheading */
     }

    .paperLink {
        font-size: 1.5em;
        font-family: 'Poppins-Regular';
        /* text-align: end; */
        margin-top: 10px;
        margin-bottom: 60px;
    }

    .paperLinkButton {
        color: black;
        text-decoration: underline;
        background: none;
        border: none;
        font-size: inherit;
        font-family: inherit;
        padding: 0;
        cursor: pointer;
        transition: transform 0.2s ease;
    }

    .paperLinkButton:hover {
        transform: scale(1.05);
    }
    
    .arrowWrapper {
        position: absolute;
        left: 50%;
        bottom: 5%;
        transform: translate(-50%, 0);
    }

    .arrowWrapper img {
        width: 2em;
    }





    .secondContainer {
        background-color: #ffffff;
        border: 0px solid green;
        width: 100%;
        height: 100%;
        display: grid;
        /* grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 1fr); */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 2em;
        box-sizing: border-box;
    }

    .secondContainer > *{
        /* border: 2px solid red; */
    }

    .demoContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1em;

        --vibration-color: red;
    }

    .wordDisplay{
        /* border: 2px solid pink; */
        font-size: 2rem;
        display: flex;
        gap: 0.15em;
    }

    .displayLetter{
        transition: 0.3s;
    }

    .displayLetter.unwritten{
        color: grey;
    }


    .displayLetter.writing{
        text-decoration: underline;
        color: var(--vibration-color);
    }

    .displayLetter.written{
        color: black;
    }

    .demoControls{
        display: flex;
        flex-direction: column;
        gap: 0.5em;

        align-items: center;
    }

    .textBufferInput{
        padding: 0.5em;
        font-size: 1.2em;
    }

    .sendLettersButton{
        padding: 0.5em;
        background-color: white;
        box-sizing: border-box;
        cursor: pointer;
        font-size: 1.15em;

        width: fit-content;
    }

    .sendLettersButton:hover{
        /* background-color: rgb(197, 197, 197); */
        /* padding: 0.65em; */
        scale: 1.065;

        transition: 0.2s;
    }

    .wristsContainer{
        display: flex;
        align-items: center;
        justify-content: center;

        gap: 2em;
    }

    .wristContainer{
        width: 10em;
        height: 10em;
        /* border: 2px solid black; */

        position: relative;
    }

    .wristContainer > p{
        position: absolute;
        left: 50%;
        top: 90%;

        transform: translate(-50%, -50%);
    }

    .vibracelet-demo-icon{
        position: absolute;
        top:56%;
        left:50%;

        transform: translate(-50%, -50%);
    }

    .demoDot{
        position: absolute;
        width: 2em;
        height: 2em;
        border-radius: 100%;

        z-index: 2;
                /* background-color: grey; */
    }

    .demoDot:hover{
        animation: buzzDot 0.2s infinite;
    }

    .leftDot1, .rightDot1{
        left: 50%;
        top: 20%;
        background-color: grey;

        transform: translate(-50%, -50%);
    }

    @keyframes buzzDot {
        0% { transform: translate(calc(-50% + 0px), calc(-50% + 0px)); }
        25% { transform: translate(calc(-50% - 1px), calc(-50% + 1px)); }
        50% { transform: translate(calc(-50% + 1px), calc(-50% - 1px)); }
        75% { transform: translate(calc(-50% - 1px), calc(-50% - 1px)); }
        100% { transform: translate(calc(-50% + 0px), calc(-50% + 0px)); }
    }
    

    .leftDot2, .rightDot2{
        left: 12%;
        top: 55%;
        background-color: grey;

        transform: translate(-50%, -50%);
    }

    .leftDot3, .rightDot3{
        left: 88%;
        top: 55%;
        background-color: grey;

        transform: translate(-50%, -50%);
    }

    .dotTesting{
        background: var(--vibration-color);
        border: 0px solid blue;

        animation: buzzDot 0.2s infinite;
    }



    .gridItem {
        /* border: 2px solid red; */
        width: 100%;
        height: 100%;
    }

    .topLeftImage, .bottomRightImage {
        grid-column: 1 / 3;  /* Spans columns 1-2 */
        grid-row: 1 / 3;     /* Spans rows 1-2 */
        width: 100%;
        height: 100%;
        object-fit: cover;   /* This ensures the image covers the area */
        border-radius: 20px;
        will-change: transform;  /* Optimize for animations */
        backface-visibility: hidden;  /* Prevent flickering */
    }

    .bottomRightImage {
        grid-column: 3 / 5;  /* Spans columns 3-4 */
        grid-row: 3 / 5;     /* Spans rows 3-4 */
        width: 100%;
        height: 100%;
        object-fit: cover;
        padding-right: 5px;
        border-radius: 20px;
    }

    .progress-dots{
        position: absolute;
        right: 1em;
        top:50%;
        z-index: 5;

        display: flex;
        flex-direction: column;
        align-items: center;
        opacity: 0.65;
        gap:6px;
    }

    .progress-dot{
        width: 8px;
        height: 8px;
        border-radius: 100%;
        /* background-color: black; */
        border: 1px solid black;

    }

    .progress-dot{
        cursor: pointer;
    }

    .progress-dot.current{
        border-radius: 100%;
        background-color: black;
        border: 1px solid black;
    }

    .topRightText {
        grid-column: 3 / 5;  /* Spans columns 3-4 */
        grid-row: 1 / 3;     /* Spans rows 1-2 */
        display: flex;
        flex-direction: column;
        justify-content: center;  /* Vertically center content */
        padding: 20px;
    }

    .topRightText h2 {
        font-family: 'Roboto Mono';
        font-size: 1.5em;
        margin-bottom: 15px;
        text-align: left;
    }

    .topRightText p {
        font-family: 'Poppins-Regular';
        font-size: 1.1em;
        line-height: 1.5;
        text-align: left;
    }

    .bottomLeftText {
        grid-column: 1 / 3;  /* Spans columns 1-2 */
        grid-row: 3 / 5;     /* Spans rows 3-4 */
        display: flex;
        flex-direction: column;
        justify-content: center;  /* Vertically center content */
        align-items: flex-end;    /* This will align the content to the right */
        padding: 20px;
    }

    .bottomLeftText h2 {
        font-family: 'Roboto Mono';
        font-size: 1.5em;
        margin-bottom: 15px;
        text-align: right;
    }

    .bottomLeftText p {
        font-family: 'Poppins-Regular';
        font-size: 1.1em;
        line-height: 1.5;
        text-align: right;
    }

    .bottomLeftText p strong {
        font-weight: 600;  /* or any weight you prefer */
        /* You can add other styles like: */
        /* color: #000; */
        /* font-family: 'Poppins-Bold'; */
    }

    .researchContainer {
        background-color: #ffffff;
        width: 100%;
        height: 100%;
        padding: 2em 2em;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        border: 0px solid blue;
    }

    .researchHeading {
        font-family: 'Roboto Mono';
        font-size: 2.5em;
        margin-bottom: 10px;
    }

    .researchText {
        font-family: 'Poppins-Regular';
        font-size: 1em;
        line-height: 1.5;
        max-width: 60em;
    }

    .prototypeBox {
        background-color: #F5F5F5;
        width: calc(100% + 30px);
        padding: 1em;
        margin-top: 30px;
        margin-left: -30px;
        box-sizing: border-box;
        border-radius: 20px;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .prototypeHeading {
        font-family: 'Roboto Mono';
        font-size: 1.8em;
        margin-bottom: 10px;
    }

    .prototypeSubheading{
        font-family: 'Roboto Mono';
        font-size: 1.2em;
        margin-bottom: 15px;
        color: #000000;
    }

    .prototypeList {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .prototypeList li {
        font-family: 'Poppins-Regular';
        font-size: 1em;
        line-height: 1.8;
        margin-bottom: 8px;
        padding-left: 20px;
        position: relative;
    }

    .prototypeList li:before {
        content: "•";
        position: absolute;
        left: 0;
    }

    .prototypeContent {
        display: flex;
        gap: 30px;
        align-items: center;
        height: 100%;
    }

    .prototypeLeft {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .prototypeImages {
        display: flex;
        flex-direction: row;
        gap: 20px;
        width: 600px;
        height: 400px;
        align-self: center;
    }

    .prototypeImage1, .prototypeImage2 {
        width: 280px;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    .researchBox{
        display: flex;
        flex-direction: column;
        gap: 0.5em;
    }

    .researchBox h2{
        margin-top: 0.5em;
    }

    .earlyPrototypesContainer {
        background-color: #ffffff;
        width: 100%;
        height: 100%;
        /* height: auto; */
        min-height: 75vh;  /* Match section height */
        /* padding: 3em; */
        border: 0px solid red;
        padding: 3em;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 3em;
        overflow-y: auto;
    }

    .earlyPrototypesBox {
        background-color: #F5F5F5;
        width: calc(100% + 30px);
        padding: 30px;
        margin-left: -30px;
        box-sizing: border-box;
        border-radius: 20px;
    }

    .earlyPrototypesHeading {
        font-family: 'Roboto Mono';
        font-size: 2.5em;
        margin-bottom: 10px;
    }

    .prototypeColumns {
        display: flex;
        gap: 20px;
        margin-top: 20px;
        justify-content: space-between;
        width: 100%;
    }

    .prototypeColumnImage {
        width: 23%;  /* Slightly less than 25% to account for gaps */
        height: 200px;
        object-fit: cover;
        border-radius: 10px;
        flex: 1;  /* Allow equal flex growth */
    }

    .prototypeColumnText {
        font-family: 'Poppins-Regular';
        font-size: 0.9em;
        line-height: 1.5;
        flex: 1;  /* Allow equal flex growth */
        width: 23%;  /* Slightly less than 25% to account for gaps */
        max-width: none;  /* Remove the previous max-width constraint */
    }

    /* Update mobile responsiveness */
    @media screen and (max-width: 800px) {
        .prototypeColumns {
            flex-direction: column;
        }
        
        .prototypeColumnImage {
            width: 100%;
        }
        
        .prototypeColumnText {
            max-width: 100%;
        }
    }

    .evaluationSection {
        border: 0px solid blue;
        margin-top: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        height: 50%;  /* Set a fixed height that fits within the container */
    }

    .evaluationLeft {
        display: flex;
        flex-direction: column;
        gap: 15px;
        height: auto;  /* Ensure full height */
    }

    .evaluationRight {
        width: 100%;
        height: 60%;  /* Take full height of parent */
        display: flex;
        align-items: flex-start;  /* Align to top */
    }

    .evaluationImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
        margin-top: 0;
    }

    @media screen and (min-width: 1400px) {
        .evaluationImage {
            height: 100%;  /* Changed from 30vh */
        }
    }

    @media screen and (max-width: 1200px) {
        .evaluationRight {
            width: 100%;  /* Changed from 40vw */
        }
        .evaluationImage {
            height: 100%;  /* Changed from 22vh */
        }
    }

    .evaluationHeading {
        font-family: 'Roboto Mono';
        font-size: 2.5em;
        margin-bottom: 20px;
    }

    .evaluationText {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .evaluationText p {
        font-family: 'Poppins-Regular';
        font-size: 0.9em;
        line-height: 1.5;
    }
}

@media screen and (max-width: 1200px) {
    .expanded-project{
        font-size: 14px;
    }
}

@media screen and (max-width: 1000px) {
    .expanded-project{
        font-size: 11px;
    }
}

@media screen and (max-width: 800px) {
    .expanded-project{
        font-size: 11px;

        width: 97vw;
        height: 95vh;
        height: 95dvh;
    }

    section {
        height: 75vh;  /* Match desktop height */
    }

    .title {
        font-size: 2.5em;

        font-family: 'Roboto Mono';
        position: relative;             
    }

    .topLine {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 0px;
    }

    .vibracelet-icon{
        max-width: 25vw;
    }

    .subHeading{
        font-size: 1.5em;
    }

    .progress-dot{
        width: 6px;
        height: 6px;
        border-radius: 100%;
        /* background-color: black; */
        border: 1px solid black;

    }

    .progress-dot{
        cursor: pointer;
    }

    .progress-dot.current{
        border-radius: 100%;
        background-color: black;
        border: 1px solid black;
    }
    
    .prototypeContent {
        flex-direction: column;
        gap: 20px;
    }

    .prototypeImages {
        width: 100%;
        flex-direction: column;
        height: auto;
    }

    .prototypeImage1, .prototypeImage2 {
        width: 100%;
        height: 200px;
    }

    .prototypeBox {
        width: 100%;
        margin-left: 0;
        padding: 20px;
    }

    .prototypeList li {
        font-size: 0.9em;
        line-height: 1.6;
        margin-bottom: 12px;
    }

    .evaluationSection {
        grid-template-columns: 1fr;  /* Single column on mobile */
        gap: 20px;
    }

    .evaluationRight {
        width: 100%;
        padding: 0;  /* Remove padding on mobile */
    }

    .evaluationImage {
        height: 180px;  /* Smaller height on mobile */
        margin-top: 0;  /* Remove negative margin */
    }

    .evaluationHeading {
        font-size: 2em;  /* Slightly smaller heading */
        margin-bottom: 15px;
    }

    .evaluationText {
        gap: 10px;  /* Reduce gap between paragraphs */
    }

    .evaluationText p {
        font-size: 0.9em;
    }

    .prototypeColumns {
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    .prototypeColumnImage {
        width: 100%;
        height: 140px;  /* Increased from 50px to 70px */
        object-fit: cover;
        margin: 0;
        border-radius: 12px;
        flex: none;
    }

    .prototypeColumnText {
        width: 100%;
        max-width: none;
        font-size: 0.9em;
        padding: 0;
        flex: none;  /* Remove flex growth */
    }

    .earlyPrototypesBox {
        width: 100%;
        margin-left: 0;
        padding: 15px;  /* Slightly reduced padding */
        border-radius: 15px;
    }

    .earlyPrototypesContainer {
        max-height: none;  /* Remove the max-height constraint */
        height: auto;      /* Let it grow based on content */
        padding: 20px;     /* Reduce padding for mobile */
        overflow: visible; /* Allow content to flow naturally */
    }

    section {
        height: 100vh;     /* Full viewport height */
        overflow-y: auto;  /* Allow scrolling if needed */
    }
}

.reflectionContainer {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    padding: 50px;
    box-sizing: border-box;
    display: flex;
    gap: 40px;
}

.reflectionLeft, .reflectionRight {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.reflectionHeading {
    font-family: 'Roboto Mono';
    font-size: 2.5em;
    margin-bottom: 20px;
}

.reflectionText {
    font-family: 'Poppins-Regular';
    font-size: 1em;
    line-height: 1.5;
}

.pdfContainer {
    flex: 1;
    border-radius: 20px;
    overflow: hidden;
    padding: 20px;
}

.pdfEmbed {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 15px;
}

@media screen and (max-width: 800px) {
    .reflectionContainer {
        flex-direction: column;
        gap: 30px;
        padding: 20px;
    }

    .reflectionHeading {
        font-size: 2em;
    }

    .reflectionText {
        font-size: 0.9em;
    }

    .pdfContainer {
        height: 400px;  /* Fixed height for mobile */
        padding: 10px;
    }
}

.reflectionList {
    list-style-type: none;
    padding: 0;
    margin-top: 15px;
}

.reflectionList li {
    font-family: 'Poppins-Regular';
    font-size: 0.9em;
    line-height: 1.5;
    margin-bottom: 15px;
    padding-left: 20px;
    position: relative;
}

.reflectionList li:before {
    content: "•";
    position: absolute;
    left: 0;
}

.reflectionList li strong {
    font-weight: 600;
}

.openPdfButton {
    font-family: 'Poppins-Regular';
    font-size: 0.9em;
    padding: 8px 16px;
    margin-bottom: 15px;
    background: none;
    border: 1px solid black;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.2s ease;  /* Changed to transition all properties */
}

.openPdfButton:hover {
    background: none;  /* Remove background color */
    transform: scale(1.05);  /* Add a subtle scale effect instead */
}

.expanded-project-body {
    height: 100vh;
    overflow: hidden;
}

.slider {
    height: 80vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;  /* Back to mandatory */
    scroll-behavior: smooth;
    border-radius: 10px;
}

/* Style the scrollbar for Chrome/Safari */
.slider::-webkit-scrollbar {
    width: 8px;
    display: block;
}

.slider::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.slider::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.slider::-webkit-scrollbar-thumb:hover {
    background: #555;
}

section {
    position: relative;
    box-sizing: border-box;
    height: 80vh;
    width: 100%;
    scroll-snap-align: start;
}

/* Update mobile styles */
@media screen and (max-width: 800px) {
    section {
        height: 80vh;  /* Keep consistent with desktop */
    }
}





