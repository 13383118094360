@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@100..900&display=swap');
@font-face {
  font-family: SpektraL;
  src: url(./fonts/Spektra-CondensedBoldBackslant.ttf);
}
@font-face {
  font-family: SpektraR;
  src: url(./fonts/Spektra-CondensedBoldItalic.ttf);
}
@font-face {
  font-family: AcuminPro;
  src: url(./fonts/Acumin-RPro.otf);
}



*{ 
    /* Removes automatically added padding and margins */
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* outline: 2px red dotted; */
 }

:root{
  --viewport-height: 100vh;
  --mobile-breakpoint: 800px;

  --gradient-color-1: #000000; 
  --gradient-color-2: #f1abfc; 
  --gradient-color-3: #6c0067;  
  --gradient-color-4: #8a0d0d;
}

html{
  padding:0;
  margin: 0;
  width: calc(100vw);
  max-width: calc(100vw);
  height: 100vh;
  max-height: 100vh;
  overflow-x: none;
  overflow-y: none;
}


  ::-webkit-scrollbar{
    /* margin-left: 0.5em; */
    /* width: 8px; */
    width: 8px;
  }
  
  ::-webkit-scrollbar-track{
    /* background: hsl(120 75% 75% / 1);   */
    border-radius: 100vw;  
  
  }
  
  ::-webkit-scrollbar-thumb{
    /* background: hsl(120 100% 20% / 1);  */
    border-radius: 100vw;
    background: rgba(0, 0, 0, 0.552);
  }
  
  ::-webkit-scrollbar-thumb:hover{
    background: hsl(120 100% 10% / 1);
  
  }
  
  ::-webkit-scrollbar-corner{
   opacity:0;
  }




body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  overflow:hidden;
    position: relative;
    font-family: AcuminPro;
}


 #gradient-canvas {
    width:100vw;
    height: var(--viewport-height);


    --gradient-color-1: #043D5D; 
    --gradient-color-2: #032E46; 
    --gradient-color-3: #23B684;  
    --gradient-color-4: #0F595E;

    /* --gradient-color-1: #2a80b9; 
    --gradient-color-2: #2b3e50; 
    --gradient-color-3: #e74c3b;  
    --gradient-color-4: #f1c411; */

    --gradient-color-1: #1b041d; 
    --gradient-color-2: #f1abfc; 
    --gradient-color-3: #6c0067;  
    --gradient-color-4: #8a0d0d;
    position: absolute;
    z-index: 0;
  }

  #box {
    width: 100vw;
    height: var(--viewport-height);
    position: absolute;
    background: 
      repeating-radial-gradient(closest-corner at 1% 21%, rgba(255,0,255,.5), rgba(0,255,255,.5), #444444 1.7%), 
      repeating-radial-gradient(closest-corner at 51% 51%, #fff, #fff, rgba(0,255,0,1) 10%);
    background-size: 55px 10px;

  }
  
  #box::before {
    position: absolute;
    z-index: 2;
    inset: 0;
    background: 
      repeating-radial-gradient(closest-corner at 21% 21%, #fff, rgba(0,0,255,.5), rgb(3,0,255) 20%), 
      repeating-radial-gradient(closest-corner at 61% 21%, #fff, rgba(0,255,0,.5), rgb(3,0,255) 20%), 
      repeating-radial-gradient(closest-corner at 91% 51%, #fff, rgba(255,255,1,.5), rgb(055,255,255) 20%);
    background-size: 15px 13px, 12px 22px, 12px 22px;
    /* background-blend-mode: exclusion, normal;
    mix-blend-mode: exclusion; */
    content: '';
    opacity: 0.2;
  }

  .noise {
    position: absolute;
    background-image: url(./noise.png);
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    opacity: 10%;
    z-index: 0;
  }

  .grid-container {
    display: flex;
    flex-direction: column;
    height: var(--viewport-height);
    align-items: center;
    justify-content: center;
    color: #adadad;
    gap: 3.5rem;
    mix-blend-mode: color-dodge;
    position: relative;


  }

  .name{

    font-size: 30pt;
    padding: 0% 0 0 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #name-heading{
    /* border: 2px solid white; */
    display: flex;
    flex-direction: column;
    gap:0.05em;


  }

  .svg-name-container{
    /* border: 2px solid red; */
    /* height: fit-content; */

    /* mix-blend-mode: color-dodge; */
    /* color: red; */
    /* border: 2px solid red; */
    width:100%;

    transform: translateZ(0);
    /* height: 20vh; */
    /* display: flex; */
  }

  .svg-text{
    /* mix-blend-mode: color-dodge; */
    font-family: SpektraR, sans-serif;
    font-weight: 200;
    transform: translateZ(1);
    fill: #adadad48;
  }

  .liam{
    /* border: 2px solid black; */
    font-family: SpektraR;
    display: block;
    font-weight: 200;
    /* will-change: filter, transform; */
    /* border: 2px solid white; */

  }
  .jones{
    /* border: 2px solid black; */
    font-family: SpektraL, sans-serif;
    font-weight: 200;
    display: block;
    padding-left: 0.115em;

    /* border: 2px solid pink; */
    text-rendering: optimizeLegibility;
    /* border: 2px solid white; */
  }
  .bio {
    z-index: 2;
    font-family: AcuminPro;
    text-align: center;
    max-width: 40em;
    padding: 0 1em;
  }

  .buttons{
    font-family: AcuminPro;
    padding: 0 0 0% 0;
    display: flex;
    gap: 1rem;
    padding: 0 1em;
    max-width: 100vw;
  }

  .buttons button{
    background: none;
    color: #adadad;
    border-radius:100px;
    border: 1px solid #adadad;
    width: 10em;
    padding: 1rem;
    cursor: pointer;
    font-size: 0.85em;
    transition: transform 0.3s ease; /* Adding transition for smooth effect */

  }

  .buttons button:hover{
    transform: scale(1.05); /* Increase the scale on hover */
  }

#checklist-container{
  position: fixed;
  bottom: 2%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.692);
  color: white;

  transform: translate(-50%, 0);

  /* border: 2px solid red; */
  background-blend-mode:normal;
  mix-blend-mode: normal;
  border-radius: 1em;
  padding: 1em;

  opacity: 0.2;

}


#checklist-container:hover{
  opacity: 1;
  transition: 0.4s;

  mix-blend-mode: normal;

  z-index: 8000;
}

.checklist{
  -webkit-user-select: none; /* Safari */
  user-select: none;
  width: 90vw;
  list-style: none;
  background-blend-mode:normal;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-blend-mode:normal;


  
  padding: 0em 5em;
}


.checklist-skill{
  background-blend-mode:normal;
  cursor: pointer;

  display: flex;
  gap: 0.5em;

  align-items: center;
  justify-content: center;
  justify-content: left;
  background-blend-mode:normal;

}

.checklist-skill p{
  display: block;
  padding:0;
  margin: 0;
  line-height: 100%;

  transform: translate(0px, 0.05em);

}

.checkbox{
  width: 1.5em;
  height: 1.5em;

  /* background-color: rgb(108, 30, 30); */
  border-radius: 50%;

  transition: 0.3s;
}

.checklist-skill.checked .checkbox{
  background-color: green;
  
}

.checklist-skill.unchecked .checkbox{
  background-color: none;
  border: 2px solid white;
}


  #about-page {
    position: fixed;
    width: 90vw;
    height: 90vh;
    height: 90dvh;
    top: 50%;
    left: 50%;
    padding: 2em;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content vertically */
    gap: 2em; /* Adjust gap as necessary */
    color: white;
    border-radius: 20px;
    background-color: rgba(200, 200, 200, 0.5);
    backdrop-filter: blur(20px);
    border: 1px solid white;

    transform: translate(-50%, -50%) scale(1);
    transition: 0.3s;
  }

  @media screen and (max-width: 800px) {
    #about-page{
      height: 95vh;
      height: 95dvh;
    }
  }
  
  #about-page.hidden {
    /* display: none; */
  }

  #about-page.hidden{
    transform: translate(-50%, -50%) scale(0);
    transform-origin: 10% 75%;
}

  
  .about-close-button {
    position: fixed; /* Position fixed to the viewport */
    right: 2em; /* Adjust as necessary */
    top: 2em; /* Adjust as necessary */
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .about-close-button img {
    transition: transform 0.3s ease; /* Adding transition for smooth effect */
  }
  
  .about-close-button img:hover {
    transform: scale(1.2); /* Increase the scale on hover */
  }
  
  .about-title {
    font-size: 2rem;
    font-family: "Big Shoulders Display", sans-serif;
    text-align: center; /* Ensure the title is centered */
  }
  
  .about-container {
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    gap: 2em;
    align-items: center; /* Center content within the container */
justify-content: center;
    /* min-height: 500px; */

    /* border: 2px solid yellow; */
  }

  @media screen and (max-width: 800px){
    .about-container{
      max-width: 100%;
      width: 100%;
    }
  }
  
  .about-image-container {
    /* background-color: white;/ */
    border-radius: 20px;
    display: flex;
    /* justify-content: start; */
    width: 100%; /* Ensure the container takes the full width */

    aspect-ratio: 1/1;
    min-width: 150px;
    max-width: 15vw;
  }

  .about-image{
    border-radius: 20px;
    object-fit: cover;
    border: none;
  }

  @media screen and (max-width: 800px) {
    .about-image-container{
      width: 20vw;
      min-width: 0px;
      max-width: unset;
      border: 1px solid white;
      /* border: 2px solid blue; */
    }

    .about-image{
      /* width: 60vw;
      height: 60vw; */
      width: 100%;
    }
  }
  
  .about-content {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 5em;
    width: 100%; /* Ensure the grid takes the full width*/
    max-width: 100%;
    min-height: 40vh;



    align-items: center;
  }
  
  .about-text-content {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 1.25em;

    width: 100%;

    /* max-height: 70vh; */

    /* overflow: scroll; */
    
    /* border: 2px solid lightblue; */
  }

  .about-text {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 2em;
    min-height: 10em;

    width: 100%;

    overflow: scroll;
    height: 45vh;
    /* min-height: 55vh; */
    max-height: 45vh;


    /* border: 2px solid rebeccapurple; */
  }
  
  .about-buttons-container {
    align-self: self-start;
    display: flex;
    gap: 3em;
    width: 100%;
  }

  .about-me-mode-container{
    /* width: 100%; */

    /* border: 2px solid green; */
  }

  .mode-list{
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 0 2em;

    justify-content: space-between;
    /* border: 2px solid red; */

    /* border: 2px solid red; */
    gap: 2em
  }

  .mode-list button{
    padding: 0.5em 0.05em;
    cursor: pointer;

    border: none;
    outline: none;
    width: 8em;

    border-radius: 10px;

    opacity: 0.7;
  }

  .mode.active button{
    padding: 0.5em 0.05em;

    background-color: black;
    color: white;

    transition: 0.15s ease-in;

    opacity: 1;
  }
  
  @media screen and (max-width: 800px){
    .mode-list button{
      /* width: fit-content; */
      width: 7.5em;
      /* padding: 0.5em; */
    padding: 2.5em 0.05em;

    }

    .about-content{
      gap: 2em;
    }

    .mode-list{
      justify-content: center;
      width: 110%;
      transform: translateX(-5%);
      padding:0;
      /* gap: 6em; */
      /* justify-content: center; */
      /* align-items: center; */
    }
  }
  
  

  #contact-form{
    z-index: 3;

    width: 25em;

    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%) scale(1);

    padding: 2em;
    border-radius: 15px;
    border: 1px white solid;

    color: white;

    display: flex;
    flex-direction: column;
    gap:2em;
    align-items: center;

    background-color: rgba(200, 200, 200, 0.5);
    backdrop-filter: blur(10px);

    transition: 0.3s;

  }

#contact-form.hidden{
    transform: translate(-50%, -50%) scale(0);
    transform-origin: 90% 75%;
    opacity: 0;
}

  .contact-close-button{
    color: rgb(92, 92, 92);
    position: absolute;
    right: 0em;
    background: none;
    border: none;
    text-align: left;
    font-size: 1.45em;
    font-weight: bold;
    top:0;
    cursor: pointer;
  }




  .contact-close-button img{
    transition: transform 0.3s ease; /* Adding transition for smooth effect */
  }


  .contact-close-button img:hover {
    transform: scale(1.2); /* Increase the scale on hover */
  }
  
  .contact-header{
    display: block;
    padding: 0;
    margin: 0;
  }

  .contact-form h2 {
    font-size: 1.8rem;
    font-family: "Big Shoulders Display", sans-serif;
  }



  .socials img {
    width: 40px;
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform 0.3s ease; /* Adding transition for smooth effect */
  }
  
  .socials img:hover {
    transform: scale(1.2); /* Increase the scale on hover */
  }

 

  .form-container{
    display: flex;
    flex-direction: column;
    gap:0.25em;
    border: 1px solid white;
    color: white;
    border-radius: 20px;

    padding: 0.75em;

    width: 100%;
  }




  .form-container input{
    /* height:2.5em; */
    width: 100%;
    text-align: start;
    color: white;
    border: none;
    background: none;
    font-family: AcuminPro;
  }


  .form-container label{
    height: 1.2rem;
    transition: 0.26s ease-in-out;
  }

  .form-container:hover label{
    font-size: 0.8em;

  }

  .message-content{
    /* height: 8em; */
    color: white;
    height: 5em;
    background: none;
    border: none;
    font-family: AcuminPro;
    resize:none;
  }

  .message-content:focus{
    outline: none;
  }

  .contact-form input:focus{
    outline: none;
  }

  /* input[type="email"]:valid{
    border: 2px solid green;
    color: green;
  } */

  
  .contact-form .send-button{
      width: 100%;
      height: 3em;
      color: white;
      background: none;
      background-blend-mode:color-dodge;


      padding:0.5em;
      border: 1px white solid;
      border-radius: 20px;

      cursor: pointer;
      transition: transform 0.3s ease; /* Adding transition for smooth effect */

      
  }


  .contact-form.hidden{

    display: none;
  }


  textarea.validityChecked, input.validityChecked{
    color:rgb(255, 102, 102);
    /* border: 2px solid blue; */
  }

  textarea.validityChecked.valid, input.validityChecked.valid{
    color: rgb(120, 219, 120);
  }


#btn-gra {
    position: relative;
    height: 3em;
    width: 100%;
    text-align: center;
    font-size: 1em;
    color: #fff;
    background: black; /* Make the background transparent */
    border: 2px solid transparent; /* Initial border size, transparent to start */
    box-sizing: border-box;
    border-radius: 30px;
    z-index: 1;
    cursor: pointer;
    overflow: hidden; /* Ensure the pseudo-elements are contained */
    transition: transform 0.3s ease; /* Adding transition for smooth effect */

}

#btn-gra:before, #btn-gra:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 30px; /* Match the border-radius of the button */
    background: linear-gradient(90deg, var(--gradient-color-2), var(--gradient-color-2), var(--gradient-color-4), var(--gradient-color-3));
    background-size: 150%;
    z-index: -1;
}

#btn-gra:before {
    padding: 2px; /* Initial border size */
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude; /* Subtract the inner part */
    -webkit-mask-composite: destination-out; /* For Safari */
    animation: slowAnimate 20s linear infinite; /* Slow animation by default */
}

#btn-gra:after {
    filter: blur(20px);
    opacity: 0;
    animation: slowAnimate 10s linear infinite; /* Slow animation by default */
}

#btn-gra:hover:before {
    animation: animate 8s linear infinite; /* Fast animation on hover */
    padding: 3px; /* Increase the border size by 1px on hover */
}

#btn-gra:hover:after {
    opacity: 1;
    animation: animate 4s linear infinite; /* Fast animation on hover */
}

@keyframes animate {
    0% {
        background-position: 0%;
    }
    100% {
        background-position: 400%;
    }
}

@keyframes slowAnimate {
    0% {
        background-position: 0%;
    }
    100% {
        background-position: 400%;
    }
}






  h1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:0;
    line-height: 0.9em;
  }

.projects-page{
  width: 90vw;
  height: 90vh;
  height: 90dvh;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  position: fixed;
  z-index: 3;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 2em;
  border-radius: 20px;
  transition: 0.3s;
  color:white; /*Title of*/
  background: none;

}

@media screen and (max-width: 800px) {
  .projects-page{
    height: 95vh;
    height: 95dvh;
  }
}

.project-open{
  /* border: 5px solid red;
  background-color: none; */
}

.project-open .top{
  filter: blur(5px);
}




.projects-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.5);
  backdrop-filter: blur(20px);
  border: 1px white solid;
  overflow: auto;
  border-radius: 20px;
}

.projects-backdrop::before {
  background: none;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place it behind the content */
}

.projects-page h2 {
  font-size: 1.8rem;
  font-family: "Big Shoulders Display", sans-serif;
}

.projects-page.hidden{
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center 75%;
}


.projects-container{
  color:white;
  display: grid;
  --min-column-width:300px;
  --min-row-height:300px;
  grid-template-columns: repeat(auto-fit, minmax(var(--min-column-width), 1fr) );
  grid-template-rows: repeat(auto-fit, minmax(var(--min-row-height), 1fr));
  position: relative;
  gap: 1em;
  padding: 1em 0em 1em 0em;
  overflow-y: scroll;
}

.top{
  position: relative;
  width: 100%;
  text-align: center;
}

.top h2 {
  font-size: 40px;
}

.projects-close-button{
  position: absolute;
  right: 0em;
  background: none;
  border: none;

  top:0;
  cursor: pointer;
}

.projects-header{
  display: block;
  padding: 0;
  margin: 0;
}

.close-button{
  display: block;
  /* transform: translate(-50%, -50%); */
  width: 20px;
  /* border: 2px solid red; */
}

.projects-close-button img {
  transition: transform 0.3s ease; /* Adding transition for smooth effect */
}


.projects-close-button img:hover {
  transform: scale(1.2); /* Increase the scale on hover */
}



.project {
  width: 100%;
  min-height: var(--min-row-height);
  position: relative;
  color: black;
  border: 0px solid black;
  mix-blend-mode: normal;
  border-radius: 15px;
  transition: transform 0.3s ease, z-index 0.3s ease; /* Smooth transition for scale and z-index */
}



.project-info{
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;


  
  /* border: 2px solid black; */

  width: 70%;

  color: white;
  z-index: 3;
}

.tags{
  display: flex;
  gap:0.5em;
  opacity: 0.9;
}

.tag{
  font-size: 0.9rem;
  width: fit-content;
  height: fit-content;
  background:#00000008;
  backdrop-filter: blur(5px);
  border: 1px white solid;
  color: rgb(255, 255, 255);
  padding:0.3em 0.7em 0.1em 0.7em;
  border-radius: 50px;
  margin: 0;
  text-wrap: nowrap;

  
}

.project-thumbnail-title{
  font-size: 1.4rem;
  text-align: center;
}

.project-thumbnail-subtitle{
  text-align: center;
  max-width: 100%;
}

.project-thumbnail-image{
  /* position: absolute; */
  width: 100%;
  height: 100%;
  object-fit: cover;



  border-radius: 15px;
  z-index: 1;
}




.overlay {
  position: absolute;
  bottom: 0;
  left:0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.403), rgba(0, 0, 0, 0.059));
  z-index: 2;
  border-radius: inherit;
  /* opacity: 0.8; */
}


.project-button{
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: inherit;
}



.project-button:hover{
  transition: 0.3s;
  /* transform: scale(1); */
  /* opacity: 0.5; */
  /* z-index: 1; */
}



.project > *{
  mix-blend-mode: normal;
}

.projects-container > :nth-child(1){
    grid-column: span 1;
    grid-row: span 1;
}

.projects-container > :nth-child(2){
  grid-column: 3 / span 2;
  grid-row: span 1;
}

.projects-container > :nth-child(3){
  grid-column: 3/ span 2;
  grid-row:  1 / span 1;
}

.projects-container > :nth-child(4){
  grid-column: span 2;
  grid-row: 2 / span 1;
}
.projects-container > :nth-child(5){
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
}




.project{
  cursor: pointer;
}

.hidden-project .expanded-project {
  display: none;
}

@media screen and (max-width: 800px) {
  html{
    font-size: 14px
  }

  .about-content{
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }

  .about-content *{
    /* max-width: 100%; */

  }

  .projects-container{
    grid-template-columns: 1fr;
    justify-content: center;

    --min-column-width:300px;
    --min-row-height:250px;
  }

  .projects-container .project{
    grid-row: unset;
    grid-column: unset;

  }

  .project{
    max-width: 100vw;
    /* width: 99vw; */
  }

  .projects-container{
    max-width: 100%;
  }

  .mode-list{
    gap: 0.5em;
  }

  .mode.active button, .mode button{
    padding: 0.35em 0.05em;
  }

  .project-button{
    width: 100%;
    max-width: 100%;
    /* width: 100%; */
    /* height: 100%; */
    position: relative;
    border-radius: inherit;
  }
}